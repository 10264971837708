import React from 'react';

import axios from 'axios';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  DeleteButton,
  useRecordContext,
  useNotify,
  Filter,
  SelectInput,
} from 'react-admin';
import { Button } from '@material-ui/core';

import { API_URL, AUTH_TOKEN_NAME, USER_ROLES } from '../../constants';

const ResetPasswordButton = () => {
  const record = useRecordContext();
  const notify = useNotify();

  const resetPassword = async () => {
    try {
      const token = localStorage.getItem(AUTH_TOKEN_NAME);
      await axios.post(
        `${API_URL}/reset-password`,
        { email: record.email },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      notify('Reset password link has been sent to user', { type: 'info' });
    } catch (error) {
      console.log(error.response.data);
      notify(error?.response?.data?.message, { type: 'error' });
    }
  };

  if (!record) return null;

  return (
    <div>
      <Button color="primary" onClick={resetPassword}>
        Reset Password
      </Button>
    </div>
  );
};

const UserFilter = (props) => (
  <Filter {...props}>
    <SelectInput
      source="role"
      choices={[
        { id: USER_ROLES.SUPER_ADMIN, name: 'Super admin' },
        { id: USER_ROLES.ADMIN, name: 'Admin' },
      ]}
      alwaysOn
    />
  </Filter>
);

export default function UsersList(props) {
  return (
    <List
      {...props}
      sort={{ field: 'createdAt', order: 'DESC' }}
      exporter={false}
      filters={<UserFilter />}
    >
      <Datagrid>
        <TextField source="email" />
        <TextField source="role" />
        <DateField source="createdAt" />
        <EditButton basePath="/users" />
        <DeleteButton />
        <ResetPasswordButton />
      </Datagrid>
    </List>
  );
}
