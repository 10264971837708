import React from 'react';
import {
  Create,
  SelectInput,
  SimpleForm,
  TextInput,
  // ImageInput,
  // ImageField,
  required,
  useRedirect,
} from 'react-admin';
import { USER_ROLES } from '../../constants';
// import { IMAGE_MAX_SIZE, IMAGE_MIME_TYPES } from '../../constants';

export default function CreateUser(props) {
  const redirect = useRedirect();
  return (
    <Create
      title="Create a user"
      {...props}
      onSuccess={() => redirect('/users')}
    >
      <SimpleForm>
        <TextInput source="name" validate={[required()]} />
        <TextInput source="email" validate={[required()]} />
        <TextInput source="companyName" validate={[required()]} />
        <TextInput source="title" validate={[required()]} />
        <TextInput source="password" validate={[required()]} />
        <SelectInput
          source="role"
          choices={[{ id: USER_ROLES.SUPER_ADMIN, name: 'Super admin' }]}
          allowEmpty
        />
        {/* <ImageInput
          source="avatarImage"
          accept={IMAGE_MIME_TYPES}
          maxSize={IMAGE_MAX_SIZE}
          label="Avatar"
          validate={[required()]}
        >
          <ImageField source="src" title="title" />
        </ImageInput> */}
      </SimpleForm>
    </Create>
  );
}
